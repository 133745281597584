<template>
  <div class="main" data-title="意向客户登记" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">意向客户登记</h2>
      </div>
    </div>
    <el-form :model="form" :rules="rules" class="form" label-position="top" ref="form">
      <el-card class="main-content register-box" shadow="hover">
        <div class="jue-card-header">
          <div class="jue-card-title">客户信息</div>
          <div class="jue-card-btns">
            <el-button
              @click="$router.push({name: 'customer-list'})"
              icon="el-icon-coffee"
              type="primary"
            >客户信息列表</el-button>
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="销售顾问" prop="seller_uid">
              <el-select
                :disabled="changeSell"
                filterable
                placeholder="请选择"
                v-model="form.seller_uid"
              >
                <template v-for="(item, key) in $store.state.userList">
                  <el-option
                    :key="key"
                    :label="item[0]"
                    :value="parseInt(key)"
                    v-if="item[3] === 1 && item[2] === 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="来电/店">
              <el-select placeholder="请选择" v-model="form.income_type">
                <el-option :value="1" label="来店"></el-option>
                <el-option :value="2" label="来电"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="进店日期">
              <el-date-picker
                @change="ifInviteIncome"
                format="yyyy年MM月dd日"
                placeholder="选择日期时间"
                type="date"
                v-model="form.income_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <div class="time_begin_end">
              <el-form-item label="进店时间" prop="income_time_begin">
                <el-time-select
                  :default-value="nowTime"
                  :picker-options="{
      start: '00:00',
      step: '00:01',
      end: '23:59',
      maxTime: form.income_time_end
    }"
                  @change="setTimeSection"
                  placeholder="进店时间"
                  v-model="form.income_time_begin"
                ></el-time-select>
              </el-form-item>

              <div style="width: 10px"></div>
              <el-form-item label="离店时间" prop="income_time_end">
                <el-time-select
                  :default-value="nowTime"
                  :picker-options="{
      start: '00:00',
      step: '00:01',
      end: '23:59',
      minTime: form.income_time_begin
    }"
                  @change="setTimeSection"
                  placeholder="离店时间"
                  v-model="form.income_time_end"
                ></el-time-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="来店人数" prop="income_number">
              <el-input-number :max="100" :min="1" label="描述文字" v-model="form.income_number"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户类型">
              <el-select placeholder="请选择" v-model="form.client_type">
                <template v-for="(item, index) in $store.state.cfgList.customerType">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户姓名" prop="realname">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号码" prop="mobile">
              <el-input placeholder="请输入" suffix-icon="el-icon-mobile-phone" v-model="form.mobile">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="微信号">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.wechat"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="性别">
              <el-radio :label="1" v-model="form.sex">男</el-radio>
              <el-radio :label="2" v-model="form.sex">女</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户属性">
              <el-select :disabled="true" placeholder="读取中…" v-model="form.is_first_income">
                <el-option :value="1" label="首次"></el-option>
                <el-option :value="2" label="再次"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="区域">
              <el-select placeholder="请选择" v-model="form.region">
                <template v-for="(item, index) in $store.state.cfgList.customerArea">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.has_seat">是否入座</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.has_offer">是否报价</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.is_vip">是否重点客户</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="留店时长">
              <el-input
                :disabled="true"
                placeholder="自动计算"
                suffix-icon="el-icon-timer"
                v-model="form.income_time_duration"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.has_trial">是否试驾</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.has_pick">现提车辆</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox
                :false-label="2"
                :true-label="1"
                disabled
                v-model="form.is_invite_income"
              >是否邀约</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来访时间段">
              <el-select :disabled="true" placeholder="自动计算" v-model="form.income_time_section">
                <template v-for="(item, index) in $store.state.cfgList.timeInterval">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  >{{typeof(item.sys_id)}}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="form.has_trial==1">
          <el-col :span="12">
            <el-form-item label="试驾说明">
              <el-input :rows="3" placeholder="请输入" type="textarea" v-model="form.trial_memo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card class="main-content register-box" shadow="hover">
        <div class="jue-card-header">
          <div class="jue-card-title">需求信息</div>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="来店目的" prop="income_purpose">
              <el-select placeholder="请选择" v-model="form.income_purpose">
                <template v-for="(item, index) in $store.state.cfgList.comeGoal">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="意向车型" prop="target_model">
              <el-cascader
                style="width: 100%"
                :options="carTypeOptions()"
                :props="{ checkStrictly: true }"
                @change="changeCarType"
                clearable
                v-model="car_type"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="变速箱类型">
              <el-select placeholder="请选择" v-model="form.transmission">
                <template v-for="(item, index) in $store.state.cfgList.gearboxType">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="需求排量">
              <el-select placeholder="请选择" v-model="form.displacement">
                <template v-for="(item, index) in $store.state.cfgList.displacement">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="外观颜色">
              <el-select placeholder="请选择" v-model="form.outer_color">
                <template v-for="(item, index) in $store.state.cfgList.outsideColor">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="内饰颜色">
              <el-select placeholder="请选择" v-model="form.inner_color">
                <template v-for="(item, index) in $store.state.cfgList.insideColor">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="线索渠道" prop="clue_source">
              <el-select @change="changeCluesource" placeholder="请选择" v-model="form.clue_source">
                <template v-for="(item, index) in $store.state.cfgList.channel">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营销分析" prop="marketing_analysis">
              <el-select placeholder="请选择" v-model="form.marketing_analysis">
                <template v-for="(item, index) in $store.state.cfgList.marketing">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="客户类别" prop="client_cate">
              <el-select placeholder="请选择" v-model="form.client_cate">
                <template v-for="(item, index) in $store.state.cfgList.customerType2">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block" label>
              <el-checkbox :false-label="2" :true-label="1" v-model="form.has_effective ">客户有效性</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="购车类型" prop="buy_type">
              <el-select placeholder="请选择" v-model="form.buy_type">
                <template v-for="(item, index) in $store.state.cfgList.buyTimes">
                <el-option
                  :key="index"
                  :label="item.f1"
                  :value="item.sys_id"
                  v-if="item.status == 1"
                ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-if="form.buy_type==21">
          <el-col :span="6">
            <el-form-item label="二手车型">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.used_model"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="评估师">
              <el-input placeholder="请输入" v-model="form.valuator"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.used_arrived">旧车是否到店</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="label-block">
              <el-checkbox :false-label="2" :true-label="1" v-model="form.used_surveyed">是否勘察</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商谈内容简述" prop="demand_memo">
              <el-input :rows="3" placeholder="请输入" type="textarea" v-model="form.demand_memo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </el-card>

      <el-card class="main-content register-box" shadow="hover" v-if="configList.length">
        <div class="jue-card-header">
          <div class="jue-card-title">其它信息</div>
        </div>

        <el-row :gutter="20">
          <el-col :key="item.sys_id" :span="6" v-for="item in configList">
            <el-form-item :label="item.name">
              <el-input
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_custom_data['field_'+item.sys_id]"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <div class="form-footer">
        <el-button @click="submitForm('form')" type="primary">立即提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { dateFormat, getTimeInterval, getCarType } from '@/unit/unit.js'
let checkMobile = null
export default {
  data() {
    checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号码'))
        }
      }
    }
    return {
      nowTime: this.$moment(new Date()).format('HH:mm'),
      notchang: false,
      userList: [],
      configList: [],
      options: {},
      changeSell: false, //是否允许改变销售
      car_type: '',
      form: {
        isdrive: false,

        seller_uid: '',
        income_type: 1,
        income_time_section: '',
        income_date: dateFormat(), //来店日期
        income_time_begin: '',
        income_time_end: '',
        income_time_duration: '',
        income_number: 1,
        client_type: '',
        realname: '',
        mobile: '',
        wechat: '',
        sex: 1,
        is_first_income: '',
        region: '',
        has_seat: 2,
        has_offer: 2,
        is_vip: 2,
        has_pick: 2,
        is_invite_income: 2,
        has_trial: 2,
        trial_memo: '',
        income_purpose: '',
        target_model: '',
        attribute: '',
        transmission: '',
        displacement: '',
        outer_color: '',
        inner_color: '',
        clue_source: '',
        marketing_analysis: '',
        has_effective: 1,
        client_nature: '',
        used_model: '',
        used_arrived: 2,
        used_surveyed: 2,
        valuator: '',
        demand_memo: '',
        client_cate: '',
        buy_type: '',
        custom_data: {},
        tmp_custom_data: {}
      },
      rules: {
        income_time_begin: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        income_time_end: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        realname: [{ required: true, message: '必需填写', trigger: 'blur' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        income_purpose: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        target_model: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        client_cate: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        buy_type: [{ required: true, message: '必需填写', trigger: 'change' }],
        demand_memo: [{ required: true, message: '必需填写', trigger: 'blur' }],
        clue_source: [{ required: true, message: '必需填写', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.init()
    this.setDefSell()
  },
  methods: {
    changeCarType() {
      if (this.car_type.length == 0) {
        this.form.target_model = ''
        this.form.attribute = ''
      }
      if (this.car_type.length == 1) {
        this.form.target_model = this.car_type[0]
        this.form.attribute = ''
      }
      if (this.car_type.length == 2) {
        this.form.target_model = this.car_type[0]
        this.form.attribute = this.car_type[1]
      }
    },
    carTypeOptions() {
      return getCarType()
    },
    changeCluesource() {
      if (this.form.clue_source == 209) {
        this.rules.marketing_analysis = [
          { required: true, message: '必需填写', trigger: 'change' }
        ]
      }
    },
    //当前时间
    currTime() {
      return new Date()
        .toLocaleTimeString('chinese', { hour12: false })
        .slice(0, 5)
    },
    setDefSell() {
      if (this.$store.state.user.role == 2) {
        this.form.seller_uid = this.$store.state.user.uid
        // this.changeSell = true
      }
    },
    init() {
      //拉取所有自定义字段
      this.$axios
        .post('/cfgfield/lists', {
          key: 'kehudengji'
        })
        .then(res => {
          this.configList = res.data.data[0].list
        })

      if (this.$route.query.sys_id) {
        this.$axios
          .post('/income/detail', {
            sys_id: this.$route.query.sys_id
          })
          .then(res => {
            this.form.sys_id = res.data.data.sys_id
            this.form.client_id = res.data.data.client_id
            this.form.seller_uid = res.data.data.seller_uid
            this.form.income_type = res.data.data.income_type
            this.form.income_date = res.data.data.income_date
            this.form.income_time_section =
              res.data.data.income_time_section != ''
                ? Number(res.data.data.income_time_section)
                : ''

            this.form.income_time_end = res.data.data.income_time_end
            this.form.income_time_begin = res.data.data.income_time_begin
            this.form.income_time_duration = res.data.data.income_time_duration
            this.form.income_number = res.data.data.income_number
            this.form.client_type = res.data.data.client_type
            this.form.realname = res.data.data.realname
            this.form.mobile = res.data.data.mobile
            this.form.wechat = res.data.data.wechat
            this.form.sex = res.data.data.sex
            this.form.is_first_income = res.data.data.is_first_income
            this.form.region =
              res.data.data.region != '' ? Number(res.data.data.region) : ''
            this.form.has_seat = res.data.data.has_seat
            this.form.has_offer = res.data.data.has_offer
            this.form.is_vip = res.data.data.is_vip
            this.form.has_trial = res.data.data.has_trial
            this.form.has_pick = res.data.data.has_pick
            this.form.is_invite_income = res.data.data.is_invite_income
            this.form.trial_memo = res.data.data.trial_memo
            this.form.income_purpose =
              res.data.data.income_purpose != ''
                ? Number(res.data.data.income_purpose)
                : ''
            this.form.target_model =
              res.data.data.target_model != ''
                ? Number(res.data.data.target_model)
                : ''
            this.form.attribute =
              res.data.data.attribute != ''
                ? Number(res.data.data.attribute)
                : ''
            // this.car_type = [this.form.target_model, this.form.attribute]
            if (this.form.attribute) {
              this.car_type = [this.form.target_model, this.form.attribute]
            } else {
              this.car_type = [this.form.target_model]
            }
            this.form.transmission =
              res.data.data.transmission != ''
                ? Number(res.data.data.transmission)
                : ''
            this.form.displacement =
              res.data.data.displacement != ''
                ? Number(res.data.data.displacement)
                : ''
            this.form.outer_color =
              res.data.data.outer_color != ''
                ? Number(res.data.data.outer_color)
                : ''
            this.form.inner_color =
              res.data.data.inner_color != ''
                ? Number(res.data.data.inner_color)
                : ''
            this.form.clue_source =
              res.data.data.clue_source != ''
                ? Number(res.data.data.clue_source)
                : ''
            this.form.marketing_analysis =
              res.data.data.marketing_analysis != ''
                ? Number(res.data.data.marketing_analysis)
                : ''
            this.form.client_cate =
              res.data.data.client_cate != ''
                ? Number(res.data.data.client_cate)
                : ''
            this.form.has_effective = res.data.data.has_effective
            this.form.buy_type =
              res.data.data.buy_type != '' ? Number(res.data.data.buy_type) : ''
            this.form.used_model = res.data.data.used_model
            this.form.used_arrived = res.data.data.used_arrived
            this.form.used_surveyed = res.data.data.used_surveyed
            this.form.valuator = res.data.data.valuator
            this.form.demand_memo = res.data.data.demand_memo
            this.form.tmp_custom_data = JSON.parse(res.data.data.custom_data)
          })
      }
    },

    //计算进店时长
    setTimeLong() {
      if (this.form.income_time_begin && this.form.income_time_end) {
        this.form.income_time_duration =
          (new Date('2020-08-22 ' + this.form.income_time_end + ':00') -
            new Date('2020-08-22 ' + this.form.income_time_begin + ':00')) /
          60000
      }
    },
    //设置来访时间段
    setTimeSection() {
      console.log(this.form.income_time_begin.length)

      if (this.form.income_time_begin) {
        this.form.income_time_section = getTimeInterval(
          this.form.income_time_end
        )
      }

      this.setTimeLong()
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let url = this.form.sys_id > 0 ? '/income/edit' : '/income/create'

          this.form.custom_data = JSON.stringify(this.form.tmp_custom_data)
          this.$axios.post(url, this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)

              this.$confirm(
                '添加成功！  是否继续添加意向客户进店记录?',
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'success'
                }
              )
                .then(() => {
                  this.resetForm('form')
                  this.setDefSell()
                })
                .catch(() => {
                  //跳转至登记列表
                  this.$router.push({ name: 'customer-list' })
                })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    ifInviteIncome() {
      if (this.form.income_date && this.form.mobile) {
        this.$axios
          .post('/client/last_trace', {
            mobile: this.form.mobile
          })
          .then(res => {
            console.log(res)
            if (
              this.form.income_date >= res.data.data.invite_income_date_begin &&
              this.form.income_date <= res.data.data.invite_income_date_end
            ) {
              this.form.is_invite_income = 1
            } else {
              this.form.is_invite_income = 2
            }
          })
      }
    }
  },
  watch: {
    'form.mobile': function() {
      const reg = /^1\d{10}$/
      if (reg.test(this.form.mobile)) {
        this.$axios
          .post('/income/last_seller', {
            mobile: this.form.mobile
          })
          .then(res => {
            if (res.data.code == 200 && res.data.data.seller_uid != 0) {
              this.form.seller_uid = res.data.data.seller_uid
              this.$forceUpdate()
              this.form.is_first_income = 2
              this.changeSell = true

              if (this.form.is_first_income == 2) {
                this.$axios
                  .post('/client/last_income', {
                    mobile: this.form.mobile
                  })
                  .then(res => {
                    if (res.data.code == 200) {
                      if (res.data.data.income_purpose != '') {
                        this.form.income_purpose = parseInt(
                          res.data.data.income_purpose
                        )
                      }
                      if (res.data.data.target_model != '') {
                        this.form.target_model = parseInt(
                          res.data.data.target_model
                        )
                      }

                      if (res.data.data.clue_source != '') {
                        this.form.clue_source = parseInt(
                          res.data.data.clue_source
                        )
                      }
                      if (res.data.data.has_effective != '') {
                        this.form.has_effective = parseInt(
                          res.data.data.has_effective
                        )
                      }
                      if (res.data.data.demand_memo != '') {
                        this.form.demand_memo = res.data.data.demand_memo
                      }
                      if (res.data.data.client_cate != '') {
                        this.form.client_cate = parseInt(
                          res.data.data.client_cate
                        )
                      }
                      if (res.data.data.buy_type != '') {
                        this.form.buy_type = parseInt(res.data.data.buy_type)
                      }
                    }
                  })
              }

              //是否邀约到店
              this.ifInviteIncome()
            } else {
              this.form.is_invite_income = 2
              this.form.is_first_income = 1
              this.changeSell = false
            }
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  /deep/ .el-input-number,
  /deep/ .el-select,
  /deep/ .el-date-editor--timerange,
  /deep/ .el-date-editor {
    width: 100%;
  }
}
.form-footer {
  text-align: right;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}

.time_begin_end {
  display: flex;
}
</style>